import React from "react";
import styled from "styled-components";
import StyledNumber from "../Atoms/StyledNumbers";
import { BodyText } from "../Atoms/Typography";
import Checkbox from "../Atoms/Checkbox";
import useStore from "../../store.js";

const CheckListWrapper = styled.div`
  border: 2px solid #333333;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px;
  height: fit-content;
  max-height: 100%;
  margin-top: 8px;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const Task = styled.div`
  display: flex;
`;

const CheckList = ({ tasks }) => {
  const setCandidateData = useStore((state) => state.setCandidateData);
  const candidate = useStore((state) => state.candidate);

  const onChangeHandler = (value) => (isChecked) => {
    setCandidateData(value, isChecked);
    console.log(value, isChecked);
  };

  return (
    <CheckListWrapper>
      <List>
        {tasks.map((task) => (
          <ListItem key={tasks.id}>
            <Task>
              <StyledNumber sequence={task.sequence} />
              <BodyText>{task.task}</BodyText>
            </Task>
            <Checkbox
              onChange={onChangeHandler(task.id)}
              checked={candidate[task.id]}
            />
          </ListItem>
        ))}
      </List>
    </CheckListWrapper>
  );
};

export default CheckList;
