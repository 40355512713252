import React from 'react';
import AppLayout from './components/Layouts/AppLayout';
import './App.css'
import { ThemeProvider } from 'styled-components';
import theme from './GlobalStyles/theme.js'


export default function App() {
  return(
    <ThemeProvider theme={theme}>
      <AppLayout/>
    </ThemeProvider>
  )
}

