export const TabsData = [
    {
      id: 1,
      to: "/candidato",
      step: "Paso 1",
      stepName: "Información general",
    },
    {
      id: 2,
      to: "/candidato/informacionadicional",
      step: "Paso 2",
      stepName: "Información adicional",
    },
    {
      id: 3,
      to: "/candidato/entrenamiento",
      step: "Paso 3",
      stepName: "Entrenamiento",
    },
    {
      id: 4,
      to: "/candidato/servicios",
      step: "Paso 4",
      stepName: "Servicios",
    },
    {
      id: 5,
      to: "/candidato/finalizar",
      step: "Paso 5",
      stepName: "Finalizar",
    },
  ];