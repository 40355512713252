import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0px 24px 0px 8px;
`
const StyledSVG = styled.svg`
    width: 32px;
    height: 32px;
    overflow: visible;
    position: absolute;
`
const StyledCircle = styled.circle`
    stroke: ${({theme}) => theme.colors.primary};
    stroke-width: 8px;
    fill: transparent;
`
const Number = styled.p`
    color: ${({theme}) => theme.colors.primary};
    font-family: 'Ibm plex sans';
    font-weight: 500;
`

const StyledNumber = (props) => {
    return(
        <Wrapper>
            <StyledSVG viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <StyledCircle cx="50" cy="50" r="50" fill="withe"/>
            </StyledSVG>
            <Number>{props.sequence}</Number>
        </Wrapper>
    )
}

export default StyledNumber