import React, { useEffect } from "react";
import Wizzard from "../Organisms/wizzard.js";
import styled from "styled-components";
import useStore from "../../store.js";
import { useNavigate } from "react-router-dom";
import SecondaryBtn from "../Atoms/SecondaryButton.js";
import { Title } from "../Atoms/Typography.js";
import { upRevel } from "../Animations.js";
import { motion } from "framer-motion";

const PageWrapper = styled(motion.div)`
  margin: 0 auto;
  padding: 0px 0px 0px 0px;
  height: 100%;
  max-width: 90vw;
`;
const CandidateHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const CandidatePage = () => {
  const authenticated = useStore((state) => state.authenticated);
  const navigate = useNavigate();
  const candidate = useStore((state) => state.candidate);

  useEffect(() => {
    if (!authenticated) {
      navigate("/login");
    }
  }, [authenticated, navigate]);

  return (
    <PageWrapper variants={upRevel} initial="hidden" animate="show" exit="exit">
      <CandidateHeader>
        <SecondaryBtn
          icon="arrow-left"
          action="Dashboard"
          onClick={() => navigate("/dashboard")}
        />
        <Title style={{ marginTop: "8px" }}>
          {candidate.fullName || "Nuevo candidato"}
        </Title>
      </CandidateHeader>
      <Wizzard />
    </PageWrapper>
  );
};

export default CandidatePage;
