import React from "react";
import Logo from "../../img/onboarding.svg";
import styled from "styled-components";
import auth from "../../lib/auth.js";
import useStore from "../../store";
import SecondaryBtn from "../Atoms/SecondaryButton";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { upRevel } from "../Animations.js";

const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
`;

const Header = () => {
  const authenticated = useStore((state) => state.authenticated);
  const setAuthenticated = useStore((state) => state.setAuthenticated);
  const navigate = useNavigate();

  const logoutHandler = () => {
    console.log("App-logoutHandler");
    auth.logout();
    setAuthenticated(false);
    navigate("/login");
  };

  return (
    <Container variants={upRevel} initial="hidden" animate="show">
      <img src={Logo} style={{ width: "200px" }} alt="" />
      {authenticated === false ? null : (
        <SecondaryBtn onClick={logoutHandler} action="Log-out" icon="log-out" />
      )}
    </Container>
  );
};

export default Header;
