import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Title } from "../Atoms/Typography.js";
import useStore from "../../store.js";
import { useNavigate } from "react-router-dom";
import Api from "../../lib/Api.js";
import ListItem from "../Atoms/ListItem.js";
import LoadingWave from "./LoadingWave.js";

const Wrapper = styled.div`
  width: ${(props) => props.width};
`;
const StyledTitle = styled(Title)`
  margin-bottom: 0px;
`;
const TableBody = styled.div`
  border: 2px solid #333333;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border-radius: 8px;
  height: fit-content;
  max-height: 100%;
  margin-top: 8px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  width: 100% !important;
`;

const TableOfContents = (props) => {
  const setCandidate = useStore((state) => state.setCandidate);
  const candidate = useStore((state) => state.candidate);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await Api.get("users");
      const { users } = response.data;
      setUsers(users);
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleSelectedUser = (username) => async () => {
    const response = await Api.get(`data/${username}`);
    const { user: currentCandidate } = response.data;
    setCandidate(currentCandidate);
    navigate("/candidato");
    console.log(currentCandidate);
    console.log(candidate);
    console.log(username);
  };
  return (
    <Wrapper width={props.width}>
      <StyledTitle>{props.title}</StyledTitle>
      <TableBody>
        {isLoading && <LoadingWave />}
        <List>
          {users.map((user) => (
            <ListItem
              key={user.username}
              onClick={handleSelectedUser(user.username)}
            >
              {user.fullName}
            </ListItem>
          ))}
        </List>
      </TableBody>
    </Wrapper>
  );
};

export default TableOfContents;
