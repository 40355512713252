import React from 'react';
import styled from 'styled-components';
import FeatherIcon from 'feather-icons-react';
import {Header} from './Typography.js'

const ButtonText = styled(Header) `
    margin-left: 8px;
    color: #333333;
    font-size: 14px;
`

const ButtonContainer = styled.div`
    width: fit-content;
    background: white;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    color: #F18C32;
    transition: 60ms ease-out;
    :hover {
        background: #f2f2f2f2;
    }
`


const SecondaryBtn = (props) => {
    return (
        <ButtonContainer onClick={props.onClick}>
            <FeatherIcon icon={props.icon} fill="none"  size="20" />
            <ButtonText>{props.action}</ButtonText>
        </ButtonContainer>
    )
}

export default SecondaryBtn