import React from 'react';
import styled from 'styled-components';
import {Title} from '../Atoms/Typography.js'
import { motion } from 'framer-motion';


const CardWrapper = styled(motion.div)`
    display:flex;
    flex-direction: column;
`

const CardBody = styled.div`
    border: 2px solid #333333;
    background-color: white;
    display:flex;
    flex-direction: column;
    padding:24px;
    border-radius: 8px;
    width: fit-content;
    margin-top: 8px;
`;

const Card = (props) => {
    return(
        <CardWrapper>
            <Title>{props.title}</Title>
            <CardBody>{props.children}</CardBody>
        </CardWrapper>
    )
}

export default Card