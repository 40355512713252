export const upRevel = {
  hidden: { opacity: 0, y: 50 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
      type: "tween",
      duration: 0.5,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    y: 0,
    transition: {
      delay: 0.3,
      type: "tween",
      duration: 0.6,
      ease: "easeInOut",
    },
  },
};

//LoadingWave Animations
export const loadingContainerVariants = {
  start: {
    opacity: 0,
    transition: {
      staggerChildren: 0.2,
    },
  },
  end: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

export const LoadingCircleVariant = {
  start: { y: "0%", background: "#F18C32" },
  end: {
    y: "100%",
    background: "#F18C32",
  },
};

export const loadingCircleTransition = {
  duration: 0.5,
  repeat: Infinity,
  repeatType: "reverse",
  ease: "easeInOut",
};
