import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion/dist/framer-motion";
import DefaultInput from "../../Atoms/Input.js";
import useStore from "../../../store.js";

const ContentWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  height: 50vh;
`;

const AditionallInfo = () => {
  const setCandidateData = useStore((state) => state.setCandidateData);
  const candidate = useStore((state) => state.candidate);

  const onChangeHandler = (key) => (event) => {
    const value = event.target.value;
    setCandidateData(key, value);
  };

  return (
    <ContentWrapper
      initial={{ opacity: 0, x: "30%" }}
      animate={{ opacity: 1, x: "0%" }}
      exit={{ opacity: 0, x: "-30%" }}
      transition={{
        ease: "backIn",
        type: "tween",
        duration: 0.2,
      }}
    >
      <DefaultInput
        value={candidate.rfc}
        type="text"
        label="RFC"
        placeholder="--"
        onChange={onChangeHandler("rfc")}
      />
      <DefaultInput
        value={candidate.curp}
        type="text"
        label="CURP"
        placeholder="--"
        onChange={onChangeHandler("curp")}
      />
      <DefaultInput
        value={candidate.nss}
        type="text"
        label="NSS"
        placeholder="--"
        onChange={onChangeHandler("nss")}
      />
      <DefaultInput
        value={candidate.ine}
        type="text"
        label="Foto de INE"
        placeholder="--"
        onChange={onChangeHandler("ine")}
      />
      <DefaultInput
        value={candidate.civil}
        type="text"
        label="Estado civil"
        placeholder="--"
        onChange={onChangeHandler("civil")}
      />
      <DefaultInput
        value={candidate.retencion}
        type="text"
        label="Carta de retencion"
        placeholder="--"
        onChange={onChangeHandler("retencion")}
      />
      <DefaultInput
        value={candidate.talla}
        type="text"
        label="Talla de playera"
        placeholder="--"
        onChange={onChangeHandler("talla")}
      />
      <DefaultInput
        value={candidate.direccion}
        type="text"
        label="Dirección"
        placeholder="--"
        onChange={onChangeHandler("direccion")}
      />
      <DefaultInput
        value={candidate.referencia}
        type="text"
        label="Referencia de dirección"
        placeholder="--"
        onChange={onChangeHandler("referencia")}
      />
      <DefaultInput
        value={candidate.comprobante}
        type="text"
        label="Foto de comprobante de domicilio"
        placeholder="--"
        onChange={onChangeHandler("comprobante")}
      />
      <DefaultInput
        value={candidate.banco}
        type="text"
        label="Banco"
        placeholder="--"
        onChange={onChangeHandler("banco")}
      />
      <DefaultInput
        value={candidate.cuenta}
        type="text"
        label="No. de cuenta bancaria o cuenta CLABE"
        placeholder="--"
        onChange={onChangeHandler("cuenta")}
      />
    </ContentWrapper>
  );
};

export default AditionallInfo;
