import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion/dist/framer-motion";
import CheckList from "../../Molecules/CheckList";

const ContentWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;

const Services = () => {
  const [tasks] = useState([
    {
      id: "emailCheck",
      sequence: 1,
      task: "Email provisionando y funcionando",
    },
    {
      id: "slackCheck",
      sequence: 2,
      task: "Slack provisionado y accesible",
    },
    {
      id: "teamsCheck",
      sequence: 3,
      task: "Acceso a Teams (opcional)",
    },
    {
      id: "jiraCheck",
      sequence: 4,
      task: "Acceso a JIRA (opcional)",
    },
    {
      id: "githubCheck",
      sequence: 5,
      task: "Acceso a Github del cliente",
    },
  ]);

  return (
    <ContentWrapper
      initial={{ opacity: 0, x: "30%" }}
      animate={{ opacity: 1, x: "0%" }}
      exit={{ opacity: 0, x: "-30%" }}
      transition={{
        ease: "backIn",
        type: "tween",
        duration: 0.2,
      }}
    >
      <CheckList tasks={tasks} />
    </ContentWrapper>
  );
};

export default Services;
