import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  LoadingCircleVariant,
  loadingContainerVariants,
  loadingCircleTransition,
} from "../Animations";

const LoadingCirclesContainer = styled(motion.div)`
  width: 4rem;
  height: 3rem;
  display: flex;
  justify-content: space-around;
`;
const LoadingCircle = styled(motion.span)`
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  background: black;
  border-radius: 1.5rem;
`;

const LoadingWave = () => {
  return (
    <LoadingCirclesContainer
      variants={loadingContainerVariants}
      initial="start"
      animate="end"
    >
      <LoadingCircle
        variants={LoadingCircleVariant}
        transition={loadingCircleTransition}
      />
      <LoadingCircle
        variants={LoadingCircleVariant}
        transition={loadingCircleTransition}
      />
      <LoadingCircle
        variants={LoadingCircleVariant}
        transition={loadingCircleTransition}
      />
    </LoadingCirclesContainer>
  );
};

export default LoadingWave;
