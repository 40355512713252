import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Header, BodyText } from "../Atoms/Typography.js";

const Tab = styled(NavLink)`
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  position: relative;
  display: flex;
  color: #bdbdbd;
  align-items: center;
  justify-content: center;
  border: ${(props) => props.borderLine};
  border-bottom: ${(props) => props.borderBottom};
  padding: 16px 16px;
  border-radius: ${(props) => props.borderRadius};
  transition: 100ms ease-out;
  position: relative;
  }
  :hover {
    background: ${(props) => props.background};
    border-radius: ${(props) => props.borderRadius};
`;

const TabText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`;

const TabHeader = styled(Header)`
  color: ${(props) => props.color};
`;

const TabBodyText = styled(BodyText)`
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 4px;
  color: ${(props) => props.subColor};
`;

const StyledTab = (props) => {
  return (
    <>
      <Tab
        to={props.to}
        borderBottom={props.borderBottom}
        borderLine={props.borderLine}
        background={props.background}
        borderRadius={props.borderRadius}
      >
        <TabText>
          <TabHeader color={props.color}>{props.step}</TabHeader>
          <TabBodyText subColor={props.subColor}>{props.stepName}</TabBodyText>
        </TabText>
      </Tab>
    </>
  );
};

export default StyledTab;
