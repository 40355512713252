import React from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import { Header } from "./Typography.js";
import { motion } from "framer-motion";

const ButtonText = styled(Header)`
  margin-left: 8px;
  color: #333333;
  font-size: 14px;
`;

const ButtonContainer = styled(motion.div)`
  width: fit-content;
  background: white;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #333333;
  border-radius: 8px;
  margin-top: ${(props) => (props.marginTop === "24px" ? "24px" : "0px")};
  cursor: pointer;
  color: #f18c32;
  transition: 60ms ease-out;
  :hover {
    background: #f18c32;
    color: white !important;
  }
  &:hover ${ButtonText} {
    color: white;
  }
`;

const Button = (props) => {
  return (
    <ButtonContainer
      onClick={props.onClick}
      whileHover={{ scale: 1.1, border: "2px solid #F18C32" }}
      whileTap={{ scale: 1.4, opacity: 0 }}
      marginTop={props.marginTop}
    >
      <FeatherIcon icon={props.icon} fill="none" size="20" />
      <ButtonText>{props.action}</ButtonText>
    </ButtonContainer>
  );
};

export default Button;
