import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion/dist/framer-motion";
import CheckList from "../../Molecules/CheckList";
import { BodyText, Title } from "../../Atoms/Typography";
import useStore from "../../../store.js";

const ContentWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;
const StyledBodyText = styled(BodyText)`
  color: #828282;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
`;

const StyledTitle = styled(Title)`
  font-size: 36px;
  margin-bottom: 24px;
`;

const SmallBodyText = styled(BodyText)`
  color: #828282;
  font-style: normal;
  font-weight: 400;
  margin-top: 16px;
`;
const AlioLink = styled(BodyText)`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

const EmailFail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`;
const Finish = (props) => {
  const candidate = useStore((state) => state.candidate);
  const setCandidateData = useStore((state) => state.setCandidateData);

  const [tasks] = useState([
    {
      id: "onboardingFinished",
      sequence: 1,
      task: "Finalizo satisfactoriamente su onboarding",
    },
  ]);

  return (
    <ContentWrapper
      initial={{ opacity: 0, x: "30%" }}
      animate={{ opacity: 1, x: "0%" }}
      exit={{ opacity: 0, x: "-30%" }}
      transition={{
        ease: "backIn",
        type: "tween",
        duration: 0.2,
      }}
    >
      <StyledBodyText>Confirmo que </StyledBodyText>
      <StyledTitle>{candidate.fullName || "Nuevo candidato"}</StyledTitle>
      <CheckList tasks={tasks} />
      <SmallBodyText>
        El usuario recibira un correo de bienvenida con recursos de alio{" "}
      </SmallBodyText>
      <EmailFail>
        <BodyText>No ha llegado el correo?</BodyText>
        <AlioLink onClick={() => setCandidateData("emailSendToUser", true)}>
          Reenviar correo
        </AlioLink>
      </EmailFail>
    </ContentWrapper>
  );
};

export default Finish;
