import React, { useEffect } from "react";
import styled from "styled-components";
import { Title } from "../Atoms/Typography.js";
import Button from "../Atoms/Button.js";
import TableOfContents from "../Molecules/TableOfContents.js";
import UserSvg from "../../img/user.svg";
import { useNavigate } from "react-router-dom";
import useStore from "../../store.js";
import { motion, AnimatePresence } from "framer-motion";
import { upRevel } from "../Animations.js";

const Wrapper = styled(motion.div)`
  widht: 100%;
  hieght: 100%;
  margin-top: 24px;
`;
const HomeHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;
const StyledImg = styled.img`
  margin-right: 8px;
`;

const User = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Home = () => {
  const authenticated = useStore((state) => state.authenticated);
  const user = useStore((state) => state.user);
  const navigate = useNavigate();
  const setCandidate = useStore((state) => state.setCandidate);

  useEffect(() => {
    if (!authenticated) {
      navigate("/login");
    }
  });

  return (
    <AnimatePresence>
      <Wrapper variants={upRevel} initial="hidden" animate="show" exit="exit">
        <HomeHeader>
          <User>
            <StyledImg src={UserSvg} />
            <Title>Hola, {user.fullName} </Title>
          </User>
          <Button
            action="Nuevo candidato"
            icon="user"
            onClick={() => {
              navigate("/candidato");
              setCandidate({});
            }}
          />
        </HomeHeader>
        <TableOfContents title="Candidates" />
      </Wrapper>
    </AnimatePresence>
  );
};

export default Home;
