import React from "react";
import styled from "styled-components";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 250px;
  margin-bottom: 8px;
  max-width: 300px;
  display: ${(props) => props.display};
`;
const Label = styled.label`
  font-family: "Ibm plex sans";
  font-weight: 600;
  letter-spacing: 0.4px;
  font-size: 10px;
  color: #333333;
  margin-bottom: 8px;
`;

const Input = styled.input`
  background: #f5f5f5;
  border: none;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
`;

const DefaultInput = (props) => {
  const { onChange: onChangeHandler, value } = props;
  return (
    <InputWrapper display={props.display}>
      <Label>{props.label}</Label>
      <Input
        value={value}
        type={props.type}
        placeholder={props.placeholder}
        onChange={onChangeHandler}
      ></Input>
    </InputWrapper>
  );
};

export default DefaultInput;
