import React from "react";
import useStore from "../../store.js";
import Header from "../Organisms/Header.js";
import Login from "../pages/Login.js";
import Candidate from "../pages/Candidate.js";
import styled from "styled-components";
import Home from "../pages/Home.js";
import { Routes, Route, Navigate } from "react-router-dom";
import Tab1 from "../Organisms/wizzardTabs/Generalinfo.js";
import Tab2 from "../Organisms/wizzardTabs/Aditionalinfo.js";
import Tab3 from "../Organisms/wizzardTabs/Training.js";
import Tab4 from "../Organisms/wizzardTabs/Services.js";
import Tab5 from "../Organisms/wizzardTabs/Finish.js";

const AppWrapper = styled.div`
  margin: 0 auto;
  height: 100vh;
  max-width: 90vw;
`;
const Content = styled.div`
  width: 100%;
  overflow: visible;
`;

const AppLayout = () => {
  const authenticated = useStore((state) => state.authenticated);

  return (
    <AppWrapper>
      <Header />
      <Content>
        <Routes>
          <Route
            path="/"
            element={
              authenticated ? (
                <Navigate to="/dashboard" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Home />} />
          <Route path="/candidato" element={<Candidate />}>
            <Route path="/candidato" element={<Tab1 />} />
            <Route path="/candidato/informacionadicional" element={<Tab2 />} />
            <Route path="/candidato/entrenamiento" element={<Tab3 />} />
            <Route path="/candidato/servicios" element={<Tab4 />} />
            <Route path="/candidato/finalizar" element={<Tab5 />} />
            <Route path="*" element={() => "404 NOT FOUND"} />
          </Route>
        </Routes>
      </Content>
    </AppWrapper>
  );
};

export default AppLayout;
