import create from "zustand";

const useStore = create((set) => ({
  authenticated: false,
  setAuthenticated: (value) => set((state) => ({ authenticated: value })),
  userData: {},
  setUserData: (key, value) =>
    set((state) => ({ userData: { ...state.userData, [key]: value } })),
  user: { username: "", fullName: "" },
  setUser: (value) => set((state) => ({ user: value })),
  candidate: { username: "", fullName: "" },
  setCandidate: (candidate) => set(() => ({ candidate: candidate })),
  setCandidateData: (key, value) =>
    set((state) => ({ candidate: { ...state.candidate, [key]: value } })),
}));

export default useStore;
