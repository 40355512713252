import useStore from "../../store";
import React, { useState } from "react";
import auth from "../../lib/auth";
import Card from "../Molecules/Card.js";
import Button from "../Atoms/Button.js";
import DefaultInput from "../Atoms/Input.js";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { upRevel } from "../Animations.js";
import LoadingWave from "../Molecules/LoadingWave.js";
import Api from "../../lib/Api";

const LoginWrapper = styled(motion.div)`
  display flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width:100%;
`;

const Login = () => {
  const setAuthenticated = useStore((state) => state.setAuthenticated);
  const setUser = useStore((state) => state.setUser);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);

  const inputHandler = (setValue) => (event) => {
    const value = event.target.value;
    setValue(value);
  };

  const loginHandler = async () => {
    try {
      setLoading(true);
      const response = await Api.post("login", { username, password });
      const { isValid, fullName, token } = response.data;
      if (isValid) {
        auth.login(token);
        const authenticated = auth.isAuthenticated();
        setAuthenticated(authenticated);
        setUser({ username, fullName });
        navigate("/dashboard");
      } else {
        alert("error");
      }
    } catch (error) {
      alert("Erro al hacer login");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AnimatePresence>
      <LoginWrapper
        variants={upRevel}
        initial="hidden"
        animate="show"
        exit="exit"
      >
        {isLoading ? (
          <LoadingWave />
        ) : (
          <Card title="Log-in">
            <h1 style={{ marginBottom: "24px" }}>Hola compañer@</h1>
            <form
              style={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              <DefaultInput
                type="text"
                label="Usuario"
                placeholder="ejemplo@alioit.com"
                onChange={inputHandler(setUsername)}
              />
              <DefaultInput
                type="password"
                label="Contraseña"
                autoComplete="off"
                placeholder="*******"
                onChange={inputHandler(setPassword)}
              />
            </form>
            <Button
              onClick={loginHandler}
              action="Log-in"
              icon="log-in"
              marginTop="24px"
            />
          </Card>
        )}
      </LoginWrapper>
    </AnimatePresence>
  );
};

export default Login;
