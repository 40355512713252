import jwt from "jsonwebtoken";
import Path from "path";

const publicKey = process.env.REACT_APP_PUBLIC_KEY;

class Auth {
  constructor() {
    this.authenticated = false;
    this.token = null;
  }

  login(token) {
    this.authenticated = true;
    this.token = token;
  }

  logout() {
    this.authenticated = false;
    this.token = null;
  }

  isAuthenticated() {
    try {
      jwt.verify(this.token, publicKey, { algorithms: ["RS256"] });
      this.authenticated = true;
      return true;
    } catch (err) {
      console.log(err.toString());
      return false;
    }
  }

  isAllowed(path) {
    try {
      const decoded = jwt.verify(this.token, publicKey, {
        algorithms: ["RS256"],
      });

      const result = decoded.allowed.find(
        (item) => Path.join("/", item) === Path.join("/", path)
      );
      if (result && this.authenticated) {
        return true;
      }

      return false;
    } catch (err) {
      return false;
    }
  }
}

export default new Auth();
