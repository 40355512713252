import React, { useState } from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";

const CheckboxWrapper = styled.div``;
const Box = styled.div`
  width: 24px;
  height: 24px;
  border: 2px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 4px;
  margin-left: 24px;
  cursor: pointer;
`;

const Checkbox = (props) => {
  const { onChange: onChangeHandler, checked } = props;
  const [isChecked, setIsChecked] = useState(checked);

  console.log("isChecked state", isChecked);

  const checkboxHandler = () => {
    setIsChecked(!isChecked);
    onChangeHandler(!isChecked);
  };

  return (
    <CheckboxWrapper onClick={checkboxHandler}>
      <Box>
        {isChecked === true ? (
          <FeatherIcon
            icon="check"
            fill="none"
            size="20"
            style={{ color: "#219653" }}
          />
        ) : null}
      </Box>
    </CheckboxWrapper>
  );
};

export default Checkbox;
