import React from "react";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import Button from "../Atoms/Button.js";
import StyledTab from "../Molecules/Tab.js";
import useStore from "../../store.js";
import Api from "../../lib/Api";
import { TabsData } from "../Commun/Data/Wizzard/TabsData.js";
import Swal from "sweetalert2";

const WizzardWrapper = styled.div`
  width: 100%;
  height: fit-content;
`;

const TabsContainer = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const TabContent = styled.div`
  width: 100%;
  padding: 24px 0px;
  overflow: scroll;
`;

const WizzardFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0px;
  border-top: 2px solid #444444;
  position: absolute;
  bottom: 0;
  width: 90%;
`;

const Wizzard = () => {
  const location = useLocation();
  const path = location.pathname;
  const candidate = useStore((state) => state.candidate);

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    popup: "swal2-show",
    showConfirmButton: false,
    timer: 1000,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const onClickHandler = async () => {
    const email = candidate.alioEmail || "";
    const username = email.split("@")[0];
    console.log("candidate", candidate);
    console.log("username", username);
    if (username) {
      await Api.put(`data/${username}`, candidate);
      console.log("candidate", candidate);
      Toast.fire({
        icon: "success",
        title: "Has guardado correctamente",
      });
    } else {
      alert("Debes agregar un correo para guardar");
    }
    console.log(username);
  };

  return (
    <WizzardWrapper>
      <TabsContainer>
        {TabsData.map((tab) => (
          <StyledTab
            key={tab.id}
            to={tab.to}
            step={tab.step}
            stepName={tab.stepName}
            borderBottom={path === tab.to ? "none" : "2px solid black"}
            borderLine={path === tab.to ? "2px solid #333333" : "none"}
            background={path === tab.to ? "unset" : "#f5f5f5"}
            color={path === tab.to ? "#f18c32" : "#BDBDBD"}
            subColor={path === tab.to ? "#444444" : null}
            borderRadius={path === tab.to ? "8px 8px 0px 0px" : null}
          />
        ))}
      </TabsContainer>
      <TabContent>
        <AnimatePresence exitBeforeEnter>
          <Outlet />
        </AnimatePresence>
      </TabContent>
      <WizzardFooter>
        <Button icon="save" action="Guardar" onClick={onClickHandler} />
      </WizzardFooter>
    </WizzardWrapper>
  );
};

export default Wizzard;
