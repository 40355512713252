import React from 'react';
import styled from 'styled-components';
import { BodyText } from './Typography';

const ListItemContainer = styled.div`
    background: #F4F4F4;
    border-radius: 8px;
    cursor: pointer;
    padding: 8px;
    &:hover {
        background: #E0E0E0E0
    }
`;

const ListItem = (props) => {
    return(
        <ListItemContainer onClick={props.onClick}>
            <BodyText>{props.children}</BodyText>
        </ListItemContainer>
    )
}

export default ListItem
