import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion/dist/framer-motion";
import DefaultInput from "../../Atoms/Input.js";
import Card from "../../Molecules/Card.js";
import useStore from "../../../store.js";
import auth from "../../../lib/auth.js";

const ContentWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  height: 50vh;
`;

const GeneralInfo = () => {
  const setCandidateData = useStore((state) => state.setCandidateData);
  const candidate = useStore((state) => state.candidate);

  const onChangeHandler = (key) => (event) => {
    const value = event.target.value;
    setCandidateData(key, value);
    console.log(key, value);
  };

  return (
    <ContentWrapper
      initial={{ opacity: 0, x: "30%" }}
      animate={{ opacity: 1, x: "0%" }}
      exit={{ opacity: 0, x: "-30%" }}
      transition={{
        ease: "backIn",
        type: "tween",
        duration: 0.3,
      }}
    >
      <DefaultInput
        value={candidate.fullName}
        type="text"
        label="Nombre completo"
        placeholder="Jorge Pérez Romo"
        onChange={onChangeHandler("fullName")}
      />
      <DefaultInput
        value={candidate.position}
        type="text"
        label="Posición"
        placeholder="Front-end developer"
        onChange={onChangeHandler("position")}
      />
      <DefaultInput
        value={candidate.client}
        type="text"
        label="Cliente"
        placeholder="Acme Inc"
        onChange={onChangeHandler("client")}
      />
      <DefaultInput
        value={candidate.startDate}
        type="text"
        label="Fecha de inicio"
        placeholder="23/01/2022"
        onChange={onChangeHandler("startDate")}
      />
      <DefaultInput
        value={candidate.location}
        type="text"
        label="Ubicación"
        placeholder="Tijuana, Baja California"
        onChange={onChangeHandler("location")}
      />
      <DefaultInput
        value={candidate.paymentScheme}
        type="text"
        label="Esquema de nómina"
        placeholder="Nómina"
        onChange={onChangeHandler("paymentScheme")}
      />
      <DefaultInput
        display={auth.isAllowed("grossSalary") ? null : "none"}
        value={candidate.grossSalary}
        type="text"
        label="Sueldo bruto mensual"
        placeholder="$30 000"
        onChange={onChangeHandler("grossSalary")}
      />
      <DefaultInput
        display={auth.isAllowed("netSalary") ? null : "none"}
        value={candidate.netSalary}
        type="text"
        label="Sueldo neto mensual"
        placeholder="$25 000"
        onChange={onChangeHandler("netSalary")}
      />
      <DefaultInput
        value={candidate.personalEmail}
        type="text"
        label="Correo electrónico personal"
        placeholder="ejemplo@gmail.com"
        onChange={onChangeHandler("personalEmail")}
      />
      <DefaultInput
        value={candidate.alioEmail}
        type="text"
        label="Email"
        placeholder="ejemplo@alioit.com"
        onChange={onChangeHandler("alioEmail")}
      />
      <DefaultInput
        value={candidate.phoneNo}
        type="text"
        label="Teléfono"
        placeholder="1231234567"
        onChange={onChangeHandler("phoneNo")}
      />
      <DefaultInput
        value={candidate.reportsTo}
        type="text"
        label="¿A quién reporta?"
        placeholder="Jonathan Zazueta"
        onChange={onChangeHandler("reportsTo")}
      />
      <DefaultInput
        value={candidate.equipmentRequired}
        type="text"
        label="¿Requiere equipo?"
        placeholder="Sí/No"
        onChange={onChangeHandler("equipmentRequired")}
      />
      <DefaultInput
        value={candidate.commonNameForEmail}
        type="text"
        label="Nombre que más utiliza para correo"
        placeholder="Jorge Pérez"
        onChange={onChangeHandler("commonNameForEmail")}
      />
      <Card title="Es freelancer? (opcional)">
        <DefaultInput
          value={candidate.adress}
          type="text"
          label="Dirección"
          placeholder="--.--"
          onChange={onChangeHandler("adress")}
        />
        <DefaultInput
          value={candidate.adressCheck}
          type="text"
          label="Foto de comprobante de domicilio"
          placeholder="--.--"
          onChange={onChangeHandler("adressCheck")}
        />
        <DefaultInput
          value={candidate.bank}
          type="text"
          label="Banco"
          placeholder="--.--"
          onChange={onChangeHandler("bank")}
        />
        <DefaultInput
          value={candidate.bankAccount}
          type="text"
          label="Cuenta bancaria o cuenta CLABE"
          placeholder="--.--"
          onChange={onChangeHandler("bankAccount")}
        />
      </Card>
    </ContentWrapper>
  );
};

export default GeneralInfo;
