import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion/dist/framer-motion";
import CheckList from "../../Molecules/CheckList";

const ContentWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;

const Training = (props) => {
  const [tasks] = useState([
    {
      id: "checkRh",
      sequence: 1,
      task: "Presentación RRHH y Alio",
    },
    {
      id: "checkSoftSkills",
      sequence: 2,
      task: "Soft skills and Cyberscurity talk",
    },
    {
      id: "checkEquipo",
      sequence: 3,
      task: "Presentación con el equipo",
    },
    {
      id: "checkComputerSetup",
      sequence: 4,
      task: "Computer setup talk",
    },
  ]);

  return (
    <ContentWrapper
      initial={{ opacity: 0, x: "30%" }}
      animate={{ opacity: 1, x: "0%" }}
      exit={{ opacity: 0, x: "-30%" }}
      transition={{
        ease: "backIn",
        type: "tween",
        duration: 0.2,
      }}
    >
      <CheckList tasks={tasks} />
    </ContentWrapper>
  );
};

export default Training;
