import styled from 'styled-components';

const Title = styled.p`
    font-family: 'Ibm plex sans';
    font-weight: 400;
    font-size: 20px;
    color: #33333;
`;

const Header = styled.p`
    font-family: 'Ibm plex sans';
    font-weight: 500;
    font-size: 14px;
    color: #333333;
`;

const BodyText = styled.p`
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    color: #33333;
    margin-bottom: 0px !important;
`;

export {Title, Header, BodyText} ;