import axios from "axios";

class Api {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    static apiKey = process.env.REACT_APP_API_KEY;
    static options = { headers: { 'x-api-key': this.apiKey } };
  
    static get(endPointPath) {
      return axios.get(`${this.baseUrl}/${endPointPath}`, this.options);
    }
  
    static post(endPointPath, body) {
      return axios.post(`${this.baseUrl}/${endPointPath}`, body, this.options);
    }
  
    static put(endPointPath, body) {
      return axios.put(`${this.baseUrl}/${endPointPath}`, body, this.options);
    }
}

export default Api;
